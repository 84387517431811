@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Plus Jakarta Sans", sans-serif;
}

#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.pintura-editor {
  --color-background: 16, 18, 20 !important;
  --color-foreground: 255, 255, 255 !important;
  --color-primary: 17, 69, 29 !important;
  --color-primary-text: 255, 255, 255 !important;
}

.PinturaButtonExport {
  background-color: #fb451d !important;
}

.react-resizable-handle {
  z-index: 100;
  background-color: red;
}
